<template>
	<div>
		<pui-datatable
			ref="documentextrafieldsgrid"
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:masterDetailColumnVisibles="masterDetailColumnVisibles()"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'documentextrafields-grid',
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'documentextrafields',
			statusportcalls: null,
			modelColumnDefs: {
				expires: {
					withoutHours: true
				},
				issued: {
					withoutHours: true
				},
				uploaddate: {
					withoutHours: true
				}
			}
		};
	},

	methods: {
		masterDetailColumnVisibles() {
			let visibleColumns = {
				typedescription: true,
				description: true,
				uploaddate: true,
				expires: true
			};
			if (this.parentModelName === 'cargo') {
				visibleColumns = {
					typedescription: true,
					description: true,
					operation: true,
					uploaddate: true,
					expires: false
				};
			}
			return visibleColumns;
		},
		createFilter() {
			const bpk = this.$puiUtils.b64ToUtf8(this.parentPk);
			const parentPkObject = JSON.parse(bpk);

			let pkValue = null;

			if (!this.$router.currentRoute.path.startsWith('/mships/')) {
				for (var key in parentPkObject) {
					const filter = { groupOp: 'and', rules: [], groups: [] };
					filter.rules.push({ field: 'model', op: 'eq', data: this.parentModelName });
					const value = parentPkObject[key];

					pkValue = !pkValue ? value : pkValue + '#' + value;
					filter.rules.push({ field: 'pk', op: 'eq', data: pkValue });
					return filter;
				}
			} else {
				let group2 = { groupOp: 'or', groups: [], rules: [] };
				for (let i = parentPkObject.version; i > 0; i--) {
					let pkValue = parentPkObject.shipid;
					pkValue = pkValue + '#' + i;
					group2.rules.push({ field: 'pk', op: 'eq', data: pkValue });
				}
				const filter = { groupOp: 'and', groups: [group2], rules: [{ field: 'model', op: 'eq', data: this.parentModelName }] };
				return filter;
			}
		}
	}
};
</script>
